<!-- 红球尾数走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1621310782405" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2538" width="45" height="45">
                        <path
                            d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
                            fill="#ffffff" p-id="2539"></path>
                        <path
                            d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
                            fill="#ffffff" p-id="2540"></path>
                        <path
                            d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
                            fill="#ffffff" p-id="2541"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国福利彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>双 色 球 红 球 -- 尾 数 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <!-- <th rowspan="2" class="ranks_a1">星期</th> -->
                        <th colspan="10" class="ranks_b">红球第一位</th>
                        <th colspan="10" class="ranks_b">红球第二位</th>
                        <th colspan="10" class="ranks_b">红球第三位</th>
                        <th colspan="10" class="ranks_b">红球第四位</th>
                        <th colspan="10" class="ranks_b">红球第五位</th>
                        <th colspan="10" class="ranks_b">红球第六位</th>


                    </tr>
                    <tr>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2 ,x) in ssq" :key='x'>
                        <td>{{item2.expect}}</td>
                        <!-- <td>{{}}</td> -->

                        <td class="red2" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[0])%10? 'redx' : ''">
                                {{item == (item2.red[0])%10 ?item:'' }}
                            </div>
                        </td>
                        <td class="red1" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[1])%10? 'redy' : ''">
                                {{item == (item2.red[1])%10 ?item:'' }}
                            </div>
                        </td>
                        <td class="red2" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[2])%10? 'redx' : ''">
                                {{item == (item2.red[2])%10 ?item:'' }}
                            </div>
                        </td>
                        <td class="red1" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[3])%10? 'redy' : ''">
                                {{item == (item2.red[3])%10 ?item:'' }}
                            </div>
                        </td>
                        <td class="red2" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[4])%10? 'redx' : ''">
                                {{item == (item2.red[4])%10 ?item:'' }}
                            </div>
                        </td>
                        <td class="red1" v-for="(item ,i) in red" :key='i'>
                            <div :class="item == (item2.red[5])%10? 'redy' : ''">
                                {{item == (item2.red[5])%10 ?item:'' }}
                            </div>
                        </td>

                    </tr>

                    <tr>
                        <td>待开奖</td>
                        <!-- <td></td> -->
                        <td v-for="(x , i) in 60" :key='i' >
                            <div :class="xred1.indexOf(i)==-1 ? 'xuan' : 'red' ">
                                {{xred1.indexOf(i)==-1 ? '?' : (i+1>9?i+1: '0'+(i+1)) }}
                            </div>
                        </td>
                     
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <!-- <td></td> -->
                        <td v-for="(x , i) in 60" :key='i' >
                            <div :class="xred1.indexOf(i)==-1 ? 'xuan' : 'red' ">
                                {{xred1.indexOf(i)==-1 ? '?' : (i+1>9?i+1: '0'+(i+1)) }}
                            </div>
                        </td>
                     
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <!-- <td></td> -->
                        <td v-for="(x , i) in 60" :key='i' >
                            <div :class="xred1.indexOf(i)==-1 ? 'xuan' : 'red' ">
                                {{xred1.indexOf(i)==-1 ? '?' : (i+1>9?i+1: '0'+(i+1)) }}
                            </div>
                        </td>
                     
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import mitt from '@/js/mitt.js'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'red-tail',
        components: {

        },
        data() { // 数据源
            return {
                red: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                xred1:[],
             
              
               
            }
        },
        mounted() {
            this.find()
        },
        computed: {
            ...mapState(['ssq'])
        },
        methods: { // 函数体部分，js的主要逻辑控制    
            ...mapMutations(['mutationssq']),

            find() {
                document.documentElement.scrollTop = 0;
                if (this.ssq == '') {
                    mitt.emit('mymsg');
                }
            },

        
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: red;
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a {
        width: 60px;
    }

    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .red1 {
        height: 23px;
        background-color: rgb(224, 191, 191);
    }

    tbody .redx {
        background-color: red;
        border-radius: 15px;
        color: #fff;
    }

    tbody .redy {
        background-color: rgb(8, 107, 189);
        border-radius: 15px;
        color: #fff;
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    tbody .lan {
        background-color: blue;
        color: #fff;
        border-radius: 15px;
    }

    .red2 {
        background-color: rgb(156, 205, 211);
    }
</style>